'use strict';

import { isEmpty, findIndex } from 'lodash';
import query from '~/graphql';
import getDiscoverEntry from '~/graphql/queries/discover/discoverEntry.graphql';

export const state = () => ({
    all: []
});

export const getters = {
    all: myState => myState.all,
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug;
        });
    }
};

export const actions = {
    getBySlug({ dispatch, getters: myGetters }, { slug, type: entryType }) {
        const entry = myGetters.getBySlug(slug, entryType)[0];

        if (entry) {
            return entry;
        }

        return dispatch('fetchBySlug', { slug, entryType });
    },

    async fetchBySlug({ commit, rootGetters }, { slug, entryType }) {
        const result = await query.call(this, getDiscoverEntry, { slug, type: entryType }),
            entry = result?.data?.entry;

        if (!isEmpty(entry)) {
            commit('setDiscoverEntry', entry);
        }

        return entry;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setDiscoverEntry(myState, entry) {
        if (findIndex(myState.all, { id: entry.id }) !== -1) {
            return;
        }

        myState.all = [
            ...myState.all,
            entry
        ];
    },
    clearCache(myState) {
        myState.all = [];
    }
};
