var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sf-hero",attrs:{"data-collapsed":_vm.isCollapsed ? 'true' : 'false',"data-has-image":!!_vm.randomImage && _vm.randomImage.url_sm}},[(_vm.randomImage && _vm.randomImage.url_sm)?_c('div',{staticClass:"sf-hero__image"},[_c('lazy-img',{staticClass:"lazy-img--cover",attrs:{"src":_vm.randomImage.url_sm,"src-tiny":_vm.randomImage.url_tiny,"srcset":`
                ${_vm.randomImage.url_xs} 320w,
                ${_vm.randomImage.url_sm} 600w,
                ${_vm.randomImage.url_md} 800w,
                ${_vm.randomImage.url_lg} 1440w,
                ${_vm.randomImage.url} 1920w
            `,"sizes":"\n                (max-width: 380px) 320px,\n                600px\n            ","width":_vm.randomImage.width,"height":_vm.randomImage.height}}),_vm._v(" "),(!!_vm.$slots.stickers)?_c('div',{staticClass:"sf-hero__stickers"},[_vm._t("stickers")],2):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sf-hero__body"},[_c('sf-page-container',[(_vm.title)?_c('h1',{staticClass:"sf-hero__title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }