<template>
    <dn-button class="sf-button" v-bind="$attrs" v-on="$listeners">
        <div v-if="!!$slots.pre" class="sf-button__pre">
            <slot name="pre" />
        </div>

        <slot />

        <div v-if="!!$slots.after" class="sf-button__after">
            <slot name="after" />
        </div>
    </dn-button>
</template>

<script>
import DnButton from '@digitalnatives/button';

export default {
    components: { DnButton }
};
</script>

<style src="./button.less" lang="less"></style>
