'use strict';

export default function getSiteIdOnLocale(axios, locale) {
    return axios.get(
        `/site/id/${locale}`
    ).then(
        (response) => {
            return response.data;
        }
    );
}
