<template>
    <sf-page-container class="sf-newsletter__container">
        <div class="sf-newsletter__wrapper">
            <sf-image-stack :images="images">
                <template slot="caption">
                    <h2>{{ $t('title') }}</h2>
                </template>
            </sf-image-stack>

            <p class="sf-newsletter__lead">{{ $t('lead') }}</p>

            <sf-button
                element="a"
                class="dn-button--large sf-newsletter__button"
                target="_blank"
                data-ga-category="nieuwsbrief-subscription"
                data-ga-action="subscribe"
                :data-ga-label="$route.path"
                :href="newsletterUrl"
            >
                <template v-slot:pre><sf-icon icon="arrow-right" /></template>
                <template v-slot:default>{{ $t('cta.label') }}</template>
            </sf-button>
        </div>
    </sf-page-container>
</template>

<script>
import SfPageContainer from '~/patterns/organisms/page-container/page-container';
import SfIcon from '~/patterns/atoms/icon/icon.vue';
import SfButton from '~/patterns/atoms/button/button.vue';
import SfImageStack from '~/patterns/molecules/image-stack/image-stack.vue';

export default {
    components: {
        SfButton,
        SfIcon,
        SfPageContainer,
        SfImageStack
    },

    data() {
        return {
            images: [
                {
                    url: '/newsletter-1.jpg',
                    url_tiny: '/newsletter-1-tiny.jpg',
                    alt: 'Bas Aan t werk',
                    title: ''
                },
                {
                    url: '/newsletter-2.jpg',
                    url_tiny: '/newsletter-2-tiny.jpg',
                    alt: 'Bas Aan t werk',
                    title: ''
                },
                {
                    url: '/newsletter-3.jpg',
                    url_tiny: '/newsletter-3-tiny.jpg',
                    alt: 'Bas Aan t werk',
                    title: ''
                }
            ]
        };
    },

    computed: {
        newsletterUrl() {
            if (this.$i18n.locale === 'en') {
                return 'https://signup.ymlp.com/xgmqqswqgmgh';
            }

            return 'https://signup.ymlp.com/xgmqqswqgmgb';
        }
    }
};
</script>

<style lang="less" src="./newsletter.less"></style>

<i18n>
{
    "nl": {
        "title": "blijf gestimuleerd",
        "cta.label": "aanmelden nieuwsbrief",
        "lead": "ontvang nieuws over subsidies, inspirerende projecten en tips voor een goede aanvraag"
    },
    "en": {
        "title": "stay stimulated",
        "cta.label": "sign up to our newsletter",
        "lead": "receive news about grants, inspiring projects and tips for a successful application"
    }
}
</i18n>
