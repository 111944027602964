<template>
    <sf-menu-list
        class="footer-menu"
    >
        <template v-if="list.sfTitle" v-slot:title>
            <h3>{{ list.sfTitle }}</h3>
        </template>
        <template
            v-if="list.listItems && list.listItems.length"
            v-slot:custom
        >
            <li v-for="(listItem, indexTwo) in list.listItems" :key="`list-item_${indexTwo}`">
                <template v-if="listItem.externalLink && isExternalLink(listItem.externalLink)">
                    <a
                        :href="listItem.externalLink"
                        target="_blank"
                    >
                        {{ listItem.label }}
                    </a>
                    <sf-icon icon="external-link" />
                </template>

                <nuxt-link
                    v-else-if="listItem.entry && listItem.entry[0]"
                    :to="$urlToPath(listItem.entry[0].url)"
                >
                    {{ listItem.label }}
                </nuxt-link>

                <nuxt-link
                    v-else-if="listItem.externalLink && !isExternalLink(listItem.externalLink)"
                    :to="getInternalLink(listItem.externalLink)"
                >
                    {{ listItem.label }}
                </nuxt-link>
            </li>
        </template>
    </sf-menu-list>
</template>

<script>
import SfMenuList from '~/patterns/molecules/menu-list/menu-list.vue';
import SfIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        SfMenuList,
        SfIcon
    },
    props: {
        list: {
            type: Object,
            default: null
        }
    },

    methods: {
        isExternalLink(link) {
            return !link.startsWith(this.$config.appURL);
        },

        getInternalLink(link) {
            // Strip leading and trailing slashes, as the graphql query doesn't
            // work with a leading slash
            const appURL = this.$config.appURL.replace(/^\/|\/$/g, '');
            return link.replace(appURL, '');
        }
    }
};
</script>

<i18n>
{
    "nl": {
        "grants": "Subsidies",
        "info": "Info",
        "search": "Zoek",
        "discover": "Ontdek"
    },
    "en": {
        "grants": "Grants",
        "info": "Info",
        "search": "Search",
        "discover": "Discover"
    }
}
</i18n>

<style src="./footer-menu.less" lang="less"></style>
