<template>
    <!--eslint-disable-->
    <div
        class="error"
        v-if="error && error.message"
        v-html="$t(error.message)"
    />
    <!--eslint-enable-->
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less" src="./error.less"></style>

<i18n>
{
    "nl": {
        "error.message.404": "We kunnen de pagina die je zoekt niet vinden.<br>Probeer het eens via <a href='/zoek'>zoeken</a> of ga terug naar de <a href='/'>homepagina</a>."
    },
    "en": {
        "error.message.404": "We can't find the page you're looking for.<br>Try <a href='/en/search'>searching</a> or return to the <a href='/en/'>homepage</a>."
    }
}
</i18n>
