<template>
    <div class="social-links">
        <a href="http://www.facebook.com/Stimuleringsfonds" target="_blank">
            <sf-icon icon="social-facebook" />
            <span class="sr-only">Stimuleringsfonds facebook</span>
        </a>
        <a href="https://www.linkedin.com/in/stimuleringsfonds/" target="_blank" class="social-links__linkedin">
            <sf-icon icon="social-linkedin" />
            <span class="sr-only">Stimuleringsfonds linkedin</span>
        </a>
        <a href="https://www.instagram.com/stimuleringsfonds/" target="_blank">
            <sf-icon icon="social-instagram" />
            <span class="sr-only">Stimuleringsfonds instagram</span>
        </a>
    </div>
</template>

<script>
import SfIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        SfIcon
    }
};
</script>

<style lang="less">
.social-links {
    padding-top: 2rem;

    a {
        border: 0 none !important; // stylelint-disable-line
    }

    a:not(:last-child) {
        margin-right: var(--spacing-sm);
    }

    a:hover {
        text-decoration: none;
        color: var(--color-secondary-dark);
    }

    &.sf-footer__social-links .dn-icon {
        margin-left: 0;
    }

    .dn-icon svg {
        height: 1.5rem;
        width: 1.5rem;
    }

    .social-links__linkedin .dn-icon svg {
        transform: scale(1.25);
    }
}
</style>
