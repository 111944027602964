<template>
    <div
        class="sf-hero"
        :data-collapsed="isCollapsed ? 'true' : 'false'"
        :data-has-image="!!randomImage && randomImage.url_sm"
    >
        <div
            v-if="randomImage && randomImage.url_sm"
            class="sf-hero__image"
        >
            <lazy-img
                class="lazy-img--cover"
                :src="randomImage.url_sm"
                :src-tiny="randomImage.url_tiny"
                :srcset="`
                    ${randomImage.url_xs} 320w,
                    ${randomImage.url_sm} 600w,
                    ${randomImage.url_md} 800w,
                    ${randomImage.url_lg} 1440w,
                    ${randomImage.url} 1920w
                `"
                sizes="
                    (max-width: 380px) 320px,
                    600px
                "
                :width="randomImage.width"
                :height="randomImage.height"
            />
            <div
                v-if="!!$slots.stickers"
                class="sf-hero__stickers"
            >
                <slot name="stickers" />
            </div>
        </div>

        <div class="sf-hero__body">
            <sf-page-container>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h1 v-if="title" class="sf-hero__title" v-html="title" />
            </sf-page-container>
        </div>
    </div>
</template>

<script>
import { sample } from 'lodash';
import SfPageContainer from '~/patterns/organisms/page-container/page-container';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img';

export default {
    components: {
        SfPageContainer,
        LazyImg
    },

    props: {
        title: {
            type: String,
            default: null
        },
        images: {
            type: Array,
            default: null
        }
    },

    computed: {
        isCollapsed() {
            return this.$store.getters['ui/isLayoutCollapsed'];
        },

        randomImage() {
            if (!this.images || !this.images.length) {
                return;
            }

            return sample(this.images);
        }
    }
};
</script>

<style lang="less" src="./hero.less" />
