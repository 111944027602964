export const NavigationVisible = {
    beforeMount() {
        this.$store.commit('ui/isNavigationVisible', true);
    },

    activated() {
        this.$store.commit('ui/isNavigationVisible', true);
    }
};

export const NavigationVisibleOnScroll = {
    computed: {
        scrollTop() {
            return this.$store.getters['ui/scrollPosition'];
        }
    },

    beforeCreate() {
        this.$store.commit('ui/isNavigationVisible', false);
    },

    activated() {
        this.$store.commit('ui/isNavigationVisible', false);
    },

    deactivated() {
        this.$store.commit('ui/isNavigationVisible', true);
    },

    watch: {
        scrollTop() {
            if (this.scrollTop > 300) {
                this.$store.commit('ui/isNavigationVisible', true);
            } else {
                this.$store.commit('ui/isNavigationVisible', false);
            }
        }
    }
};
