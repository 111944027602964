export default class SEOService {
    constructor($i18n) {
        this.$i18n = $i18n;
    }

    getMetaData(seo = {}, addTitleTemplate) {
        if (!seo) {
            return undefined;
        }

        const title = seo.title || this.$i18n.title;
        const siteName = 'Stimuleringsfonds';
        const fallbackData = {
            title: addTitleTemplate ? `${title} | ${siteName}` : title,
            description: seo.description || this.$i18n.t('seo.description'),
            siteName,
            image: '/social-image.jpg'
        };

        const metaData = {
            title: fallbackData.title,
            meta: [
                { hid: 'description', name: 'description', content: fallbackData.description },
                { hid: 'author', name: 'author', content: fallbackData.siteName },
                { hid: 'og:site_name', name: 'og:site_name', content: fallbackData.siteName },
                { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: fallbackData.siteName },
                ...this.getFbFields(seo, fallbackData),
                ...this.getTwFields(seo, fallbackData),
                this.getRobots(seo)
            ],
            htmlAttrs: {
                lang: this.$i18n.locale
            }
        };

        if (this.getCanonical(seo)) {
            metaData.link = [
                this.getCanonical(seo)
            ];
        }

        return metaData;
    }

    getFbFields(seo, fallbackData) {
        if (seo.social && seo.social.facebook) {
            return Object.keys(seo.social.facebook).map((key) => {
                // Image
                if (key === 'image' && seo.social.facebook.image) {
                    return { hid: 'og:image', property: 'og:image', content: seo.social.facebook[key].url };
                } else if (key === 'image' && seo.featuredimage) {
                    return { hid: 'og:image', property: 'og:image', content: seo.featuredimage.urlLarge };
                } else if (key === 'image') {
                    return { hid: 'og:image', property: 'og:image', content: fallbackData.image };
                }

                // Title
                if (key === 'title' && seo.social.facebook.title) {
                    return { hid: 'og:title', property: 'og:title', content: seo.social.facebook.title };
                } else if (key === 'title') {
                    return { hid: 'og:title', property: 'og:title', content: fallbackData.title };
                }

                // Description
                if (key === 'description' && seo.social.facebook.description) {
                    return { hid: 'og:description', property: 'og:description', content: seo.social.facebook.description };
                } else if (key === 'description') {
                    return { hid: 'og:description', property: 'og:description', content: fallbackData.description };
                }

                return { hid: 'og:' + key, property: 'og:' + key, content: seo.social.facebook[key] };
            });
        }

        return [
            { hid: 'og:title', property: 'og:title', content: fallbackData.title },
            { hid: 'og:description', property: 'og:description', content: fallbackData.description },
            { hid: 'og:image', property: 'og:image', content: fallbackData.image }
        ];
    }

    getTwFields(seo, fallbackData) {
        let data = [];

        if (seo.social && seo.social.twitter) {
            data = Object.keys(seo.social.twitter).map((key) => {
                // Image
                if (key === 'image' && seo.social.twitter.image) {
                    return { hid: 'twitter:image', name: 'twitter:image', content: seo.social.twitter.image.url };
                } else if (key === 'image' && seo.featuredimage) {
                    return { hid: 'twitter:image', property: 'twitter:image', content: seo.featuredimage.urlLarge };
                } else if (key === 'image') {
                    return { hid: 'twitter:image', property: 'twitter:image', content: fallbackData.image };
                }

                // Title
                if (key === 'title' && seo.social.twitter.title) {
                    return { hid: 'twitter:title', property: 'twitter:title', content: seo.social.twitter.title };
                } else if (key === 'title') {
                    return { hid: 'twitter:title', property: 'twitter:title', content: fallbackData.title };
                }

                // Description
                if (key === 'description' && seo.social.twitter.description) {
                    return { hid: 'twitter:description', property: 'twitter:description', content: seo.social.twitter.description };
                } else if (key === 'description') {
                    return { hid: 'twitter:description', property: 'twitter:description', content: fallbackData.description };
                }

                return { hid: 'twitter:' + key, name: 'twitter:' + key, content: seo.social.twitter[key] };
            });

            data.push({ hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' });

            return data;
        }

        return [
            { hid: 'twitter:title', property: 'twitter:title', content: fallbackData.title },
            { hid: 'twitter:description', property: 'twitter:description', content: fallbackData.description },
            { hid: 'twitter:image', property: 'twitter:image', content: fallbackData.image },
            { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' }
        ];
    }

    getRobots(seo) {
        let content = 'all';

        if (seo.advanced && seo.advanced.robots) {
            content = seo.advanced.robots.join(', ');
        }

        return { hid: 'robots', name: 'robots', content };
    }

    getCanonical(seo) {
        if (seo.advanced && seo.advanced.canonical) {
            return { rel: 'canonical', href: seo.advanced.canonical };
        }

        return false;
    }
}
