export default () => ({
    menu: {
        isOpen: false
    },

    navigation: {
        isVisible: false
    },

    layout: {
        isCollapsed: false
    },

    isMotionReduced: false,

    scrollPosition: 0
});
