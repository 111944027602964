import { isEmpty } from 'lodash';
import query from '~/graphql';
import getGeneral from '~/graphql/queries/global-sets/getGeneral.graphql';

export default {
    async fetchGeneralData({ commit }) {
        const result = await query.call(this, getGeneral),
            generalData = result.data?.globalSet;

        if (!isEmpty(generalData)) {
            commit('general', generalData);
        }

        return generalData;
    }
};
