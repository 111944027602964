<template>
    <sf-menu-list
        class="main-menu"
        :routes="[
            {
                path: localePath('grants'),
                label: $t('grants'),
            },
            {
                path: localePath('discover'),
                label: $t('discover')
            },
            {
                path: localePath('info'),
                label: $t('info')
            },
            {
                path: localePath('search'),
                label: $t('search')
            }
        ]"
        :index-tabs="indexTabs"
    />
</template>

<script>
import SfMenuList from '~/patterns/molecules/menu-list/menu-list.vue';

export default {
    components: {
        SfMenuList
    },
    props: {
        indexTabs: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<i18n>
{
    "nl": {
        "grants": "Subsidies",
        "info": "Info",
        "search": "Zoek",
        "discover": "Ontdek"
    },
    "en": {
        "grants": "Grants",
        "info": "Info",
        "search": "Search",
        "discover": "Discover"
    }
}
</i18n>

<style src="./main-menu.less" lang="less"></style>
