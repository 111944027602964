export default function({ app, store }) {
    app.router.afterEach((to) => {
        // Close the menu overlay
        if (store.getters['ui/isMenuOpen']) {
            store.dispatch('ui/closeMenu');
        }

        // Close any open modals
        if (store.getters['modal/activeModal']) {
            store.dispatch('modal/close');
        }
    });
}
