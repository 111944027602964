<template>
    <div
        class="sf-page-container"
        :data-collapsed="isCollapsed ? 'true' : 'false'"
    >
        <slot />
    </div>
</template>

<script>
export default {
    computed: {
        isCollapsed() {
            return this.$store.getters['ui/isLayoutCollapsed'];
        }
    }
};
</script>

<style lang="less" src="./page-container.less" />
