<template>
    <div
        v-if="showLanguageSwitch"
        class="sf-language-switch"
    >
        <nuxt-link
            v-if="showLocale('nl')"
            :to="getPathForLocale('nl')"
            :data-active="$i18n.locale === 'nl'"
            lang="nl"
            aria-label="Bekijk pagina in het Nederlands"
        >
            nl
        </nuxt-link>
        <sf-icon icon="chevron-right" />
        <nuxt-link
            v-if="showLocale('en')"
            :to="getPathForLocale('en')"
            :data-active="$i18n.locale === 'en'"
            lang="en"
            aria-label="View page in English"
        >
            eng
        </nuxt-link>
    </div>
</template>

<script>
import executeCraftQuery from '~/graphql';
import getEntry from '~/graphql/queries/getEntry.graphql';

import SfIcon from '~/patterns/atoms/icon/icon.vue';
/**
 * The language switch is shown only if the current entry is available in
 * another language, as to avoid providing 'dead' links to the Nuxt crawler.
 * These (static) routes are considered an exception to this rule.
 */
const ALWAYS_SHOW_LANGUAGE_SWITCH = [
    '/zoek',
    '/search',
    '/en/search',
    '/home',
    '/',
    '/discover',
    '/en/discover',
    '/toekenningen',
    '/en/grants-issued',
    '/nieuws',
    '/en/news',
    '/agenda',
    '/en/agenda',
    '/dossiers',
    '/en/dossiers',
    '/projecten',
    '/en/projects',
    '/publicaties',
    '/en/publications'
];

export default {
    components: {
        SfIcon
    },

    data() {
        return {
            currentEntry: {}
        };
    },

    async fetch() {
        // Strip leading and trailing slashes, as the graphql query doesn't
        // work with a leading slash
        let currentPath = this.currentPath.replace(/^\/|\/$/g, '');

        // Strip the en/ prefix from the path, as graphql won't take it.
        if (this.$i18n.locale === 'en') {
            currentPath = currentPath.replace('en/', '');
        }

        // Reset entry
        this.currentEntry = {};

        this.currentEntry = await executeCraftQuery.call(this, getEntry, {
            uri: currentPath
        }).then((response) => {
            return response.data.entry;
        });
    },

    computed: {
        currentPath() {
            return this.$route.params.pathMatch || this.$route.path;
        },

        translations() {
            const translations = {};

            if (!this.currentEntry || !this.currentEntry.translations || !this.currentEntry.translations.length > 0) {
                return translations;
            }

            this.currentEntry.translations.forEach((translation) => {
                translations[translation.language] = translation;
            });

            return translations;
        },

        showLanguageSwitch() {
            // Make an exception for pages that have no entry
            if (ALWAYS_SHOW_LANGUAGE_SWITCH.includes(this.currentPath)) {
                return true;
            }
            return this.translations.nl || this.translations.en;
        }
    },

    watch: {
        $route() {
            this.$fetch();
        }
    },

    methods: {
        showLocale(locale) {
            // Make an exception for pages that have no entry
            if (ALWAYS_SHOW_LANGUAGE_SWITCH.includes(this.currentPath)) {
                return true;
            }

            return this.$i18n.locale === locale || (this.translations && this.translations[locale]);
        },

        getPathForLocale(locale) {
            // Option 1: the same locale is requested. Show the current url.
            if (locale === this.$i18n.locale) {
                return this.currentPath;
            }

            // Option 2: There is a translated URL based on the dynamic entry. Use it.
            if (this.translations[locale] && this.translations[locale].url) {
                return this.$urlToPath(this.translations[locale].url);
            }

            // Option 3: Fall back on the i18n path
            return this.switchLocalePath(locale);
        }
    }
};
</script>

<style src="./language-switch.less" lang="less"></style>
