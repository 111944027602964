var render = function render(){var _vm=this,_c=_vm._self._c;return _c('sf-menu-list',{staticClass:"main-menu",attrs:{"routes":[
        {
            path: _vm.localePath('grants'),
            label: _vm.$t('grants'),
        },
        {
            path: _vm.localePath('discover'),
            label: _vm.$t('discover')
        },
        {
            path: _vm.localePath('info'),
            label: _vm.$t('info')
        },
        {
            path: _vm.localePath('search'),
            label: _vm.$t('search')
        }
    ],"index-tabs":_vm.indexTabs}})
}
var staticRenderFns = []

export { render, staticRenderFns }