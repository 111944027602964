import { trim, isEmpty } from 'lodash';

export default function(path, locale = '') {
    let slug = path;

    if (!isEmpty(locale)) {
        slug = path.replace(`${locale}/`, '');
    }

    return trim(slug, '/');
}
