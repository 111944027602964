<template>
    <nav class="sf-menu-actions">
        <ul>
            <li><sf-language-switch /></li>
            <li><a href="https://aanvragen.stimuleringsfonds.nl/register/" target="_blank">{{ $t('login') }}</a></li>
        </ul>
    </nav>
</template>

<script>
import SfLanguageSwitch from '~/patterns/atoms/language-switch/language-switch';

export default {
    components: {
        SfLanguageSwitch
    }
};
</script>

<style src="./menu-actions.less" lang="less"></style>

<i18n>
{
    "nl": {
        "login": "Inloggen"
    },
    "en": {
        "login": "Login"
    }
}
</i18n>
