import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5353d254 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _ab0fd4fa = () => interopDefault(import('../pages/dossiers/index.vue' /* webpackChunkName: "pages/dossiers/index" */))
const _1834cdec = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1c3845a6 = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _5e5ff38e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _7e18d93e = () => interopDefault(import('../pages/discover.vue' /* webpackChunkName: "pages/discover" */))
const _6197a716 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _f7c66310 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _2792be92 = () => interopDefault(import('../pages/grants/index.vue' /* webpackChunkName: "pages/grants/index" */))
const _586b6adc = () => interopDefault(import('../pages/grants-issued/index.vue' /* webpackChunkName: "pages/grants-issued/index" */))
const _348c61a0 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _519d310c = () => interopDefault(import('../pages/events/_slug.vue' /* webpackChunkName: "pages/events/_slug" */))
const _ae7d178a = () => interopDefault(import('../pages/dossiers/_slug.vue' /* webpackChunkName: "pages/dossiers/_slug" */))
const _56b4c994 = () => interopDefault(import('../pages/grants-issued/_slug.vue' /* webpackChunkName: "pages/grants-issued/_slug" */))
const _25dc1d4a = () => interopDefault(import('../pages/grants/_slug.vue' /* webpackChunkName: "pages/grants/_slug" */))
const _5ca95246 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _6504e9a6 = () => interopDefault(import('../pages/projects/_slug.vue' /* webpackChunkName: "pages/projects/_slug" */))
const _fb33a5a0 = () => interopDefault(import('../pages/articles/_slug.vue' /* webpackChunkName: "pages/articles/_slug" */))
const _286e3217 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agenda",
    component: _5353d254,
    name: "events___nl"
  }, {
    path: "/dossiers",
    component: _ab0fd4fa,
    name: "dossiers___nl"
  }, {
    path: "/en",
    component: _1834cdec,
    name: "index___en"
  }, {
    path: "/info",
    component: _1c3845a6,
    name: "info___nl"
  }, {
    path: "/nieuws",
    component: _5e5ff38e,
    name: "news___nl"
  }, {
    path: "/ontdek",
    component: _7e18d93e,
    name: "discover___nl"
  }, {
    path: "/projecten",
    component: _6197a716,
    name: "projects___nl"
  }, {
    path: "/publicaties",
    component: _f7c66310,
    name: "articles___nl"
  }, {
    path: "/subsidies",
    component: _2792be92,
    name: "grants___nl"
  }, {
    path: "/toekenningen",
    component: _586b6adc,
    name: "grants-issued___nl"
  }, {
    path: "/zoek",
    component: _348c61a0,
    name: "search___nl"
  }, {
    path: "/en/agenda",
    component: _5353d254,
    name: "events___en"
  }, {
    path: "/en/discover",
    component: _7e18d93e,
    name: "discover___en"
  }, {
    path: "/en/dossiers",
    component: _ab0fd4fa,
    name: "dossiers___en"
  }, {
    path: "/en/grants",
    component: _2792be92,
    name: "grants___en"
  }, {
    path: "/en/grants-issued",
    component: _586b6adc,
    name: "grants-issued___en"
  }, {
    path: "/en/info",
    component: _1c3845a6,
    name: "info___en"
  }, {
    path: "/en/news",
    component: _5e5ff38e,
    name: "news___en"
  }, {
    path: "/en/projects",
    component: _6197a716,
    name: "projects___en"
  }, {
    path: "/en/publications",
    component: _f7c66310,
    name: "articles___en"
  }, {
    path: "/en/search",
    component: _348c61a0,
    name: "search___en"
  }, {
    path: "/",
    component: _1834cdec,
    name: "index___nl"
  }, {
    path: "/en/agenda/:slug",
    component: _519d310c,
    name: "events-slug___en"
  }, {
    path: "/en/dossiers/:slug",
    component: _ae7d178a,
    name: "dossiers-slug___en"
  }, {
    path: "/en/grants-issued/:slug",
    component: _56b4c994,
    name: "grants-issued-slug___en"
  }, {
    path: "/en/grants/:slug",
    component: _25dc1d4a,
    name: "grants-slug___en"
  }, {
    path: "/en/news/:slug",
    component: _5ca95246,
    name: "news-slug___en"
  }, {
    path: "/en/projects/:slug",
    component: _6504e9a6,
    name: "projects-slug___en"
  }, {
    path: "/en/publications/:slug",
    component: _fb33a5a0,
    name: "articles-slug___en"
  }, {
    path: "/agenda/:slug",
    component: _519d310c,
    name: "events-slug___nl"
  }, {
    path: "/dossiers/:slug",
    component: _ae7d178a,
    name: "dossiers-slug___nl"
  }, {
    path: "/nieuws/:slug",
    component: _5ca95246,
    name: "news-slug___nl"
  }, {
    path: "/projecten/:slug",
    component: _6504e9a6,
    name: "projects-slug___nl"
  }, {
    path: "/publicaties/:slug",
    component: _fb33a5a0,
    name: "articles-slug___nl"
  }, {
    path: "/subsidies/:slug",
    component: _25dc1d4a,
    name: "grants-slug___nl"
  }, {
    path: "/toekenningen/:slug",
    component: _56b4c994,
    name: "grants-issued-slug___nl"
  }, {
    path: "/en/*",
    component: _286e3217,
    name: "all___en"
  }, {
    path: "/*",
    component: _286e3217,
    name: "all___nl"
  }],

  parseQuery: function(queryString) {
      return require('qs').parse(queryString);
    },
  stringifyQuery: function(object) {
      const queryString = require('qs').stringify(object);
      return queryString ? '?' + queryString : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
