<template>
    <dn-cookiebar
        v-slot="{ acceptAllCookies, isSaving, dismissCookiebar }"
        class="sf-cookiebar"
        :is-visible="!isCookiebarHidden"
    >
        <h4 class="sf-cookiebar__title">{{ $t('title') }}</h4>

        <!-- eslint-disable -->
        <p class="sf-cookiebar__message" v-html="$t('cookie-text')" />
        <!-- eslint-enable -->

        <div class="sf-cookiebar__buttons">
            <sf-button
                class="sf-cookiebar__reject dn-button--ghost"
                :data-loading="isSaving"
                @click="dismissCookiebar"
            >
                <span v-if="isSaving">{{ $t('saving') }}</span>
                <span v-else>{{ $t('reject') }}</span>
            </sf-button>

            <sf-button
                class="sf-cookiebar__accept"
                :data-loading="isSaving"
                @click="acceptAllCookies"
            >
                <span v-if="isSaving">{{ $t('saving') }}</span>
                <span v-else>{{ $t('accept') }}</span>
            </sf-button>
        </div>
    </dn-cookiebar>
</template>

<script>
import { DnCookiebar } from '@digitalnatives/cookie-consent';
import SfButton from '~/patterns/atoms/button/button';

export default {
    components: {
        DnCookiebar,
        SfButton
    },

    computed: {
        isCookiebarHidden() {
            if (this.isCookieModalOpen) {
                return true;
            }

            return this.$cookieConsent.isCookiebarDismissed() && !this.isSaving;
        },

        isCookieModalOpen() {
            return this.$store.getters['modal/activeModal'] === 'cookie-modal';
        }
    }
};
</script>

<style lang="less" src="./cookie-bar.less"></style>

<i18n>
{
    "nl": {
        "title": "Privacyinstellingen",
        "accept": "accepteren",
        "reject": "weigeren",
        "saving": "opslaan…",
        "cookie-text": "Deze website gebruikt cookies om de beste ervaring te bieden. Een van deze cookies wordt geplaatst door <a href=\"http://hotjar.com/\" target=\"_blank\">hotjar.com</a>. IP-adressen, surfgedrag en data blijven volledig anoniem. Zie voor meer informatie onze <a href=\"https://cms.stimuleringsfonds.nl/storage/media/SCI_Privacystatement-2022.pdf\" target=\"_blank\">privacy policy</a>."
    },
    "en": {
        "title": "Privacy settings",
        "accept": "accept all",
        "reject": "reject",
        "saving": "saving…",
        "cookie-text": "This website uses cookies to provide the best experience. One such cookie is set by <a href=\"http://hotjar.com/\" target=\"_blank\">hotjar.com</a>. IP addresses, browsing behaviour and data remain completely anonymous. For more information, see our <a href=\"https://cms.stimuleringsfonds.nl/storage/media/SCI_Privacystatement-2022.pdf\" target=\"_blank\">privacy policy</a> (in Dutch only)."
    }
}
</i18n>
