'use strict';

import { isEmpty } from 'lodash';
import query from '~/graphql';
import getPage from '~/graphql/queries/pages/getPage.graphql';
import getPages from '~/graphql/queries/pages/getPages.graphql';

export const HOME_URI = '__home__';

export const state = () => ({
    all: []
});

export const getters = {
    all: myState => myState.all,
    getHome(myState, myGetters, rootState, rootGetters) {
        return () => myState.all.filter((item) => {
            return item.uri === HOME_URI;
        });
    },
    getGrantArchive(myState, myGetters, rootState, rootGetters) {
        return uri => myState.all.filter((item) => {
            return item.uri === uri;
        });
    },
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug;
        });
    },
    getByUri(myState, myGetters, rootState, rootGetters) {
        return (uri, siteId = null) => myState.all.filter((item) => {
            let match = item.uri === uri;
            if (siteId) {
                match = match && siteId === item.siteId;
            }
            return match;
        });
    },
    getById(myState, myGetters, rootState, rootGetters) {
        return id => myState.all.filter((item) => {
            return item.id === `${id}`;
        });
    }
};

export const actions = {
    getById({ dispatch, getters: myGetters }, { id }) {
        const page = myGetters.getById(id)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchById', { id });
    },
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const page = myGetters.getBySlug(slug)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchBySlug', { slug });
    },
    getByUri({ dispatch, getters: myGetters, state: myState }, { uri }) {
        const page = myGetters.getByUri(uri, this.$i18n.localeProperties.craftSiteId)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchByUri', { uri });
    },
    getHome({ dispatch, getters: myGetters }) {
        const uri = HOME_URI,
            page = myGetters.getByUri(uri)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchByUri', { uri });
    },

    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getPages),
            entries = result.data.entry;

        if (!isEmpty(entries)) {
            commit('setPages', entries);
        }

        return entries;
    },
    async fetchById({ commit, rootGetters }, { id }) {
        const result = await query.call(this, getPage, { id }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setPage', entry);
        }

        return entry;
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getPage, { slug }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setPage', entry);
        }

        return entry;
    },
    async fetchByUri({ commit, rootGetters }, { uri }) {
        const result = await query.call(this, getPage, { uri }),
            entry = result.data?.entry;

        if (!isEmpty(entry)) {
            commit('setPage', entry);
        }

        return entry;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setPages(myState, pages) {
        myState.all = pages;
    },
    setPage(myState, page) {
        myState.all = [
            ...myState.all,
            page
        ];
    },
    clearCache(myState) {
        myState.all = [];
    }
};
