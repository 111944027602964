'use strict';

import { isEmpty, findIndex } from 'lodash';
import query from '~/graphql';
import getGrant from '~/graphql/queries/grants/getGrant.graphql';
import getGrants from '~/graphql/queries/grants/getGrants.graphql';

export const state = () => ({
    all: []
});

export const getters = {
    all: myState => myState.all,
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug;
        });
    },
    getByUri(myState, myGetters, rootState, rootGetters) {
        return uri => myState.all.filter((item) => {
            return item.uri === uri;
        });
    },
    getById(myState, myGetters, rootState, rootGetters) {
        return id => myState.all.filter((item) => {
            return item.id === `${id}`;
        });
    }
};

export const actions = {
    getById({ dispatch, getters: myGetters }, { id }) {
        const grant = myGetters.getById(id)[0];

        if (grant) {
            return grant;
        }

        return dispatch('fetchById', { id });
    },
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const grant = myGetters.getBySlug(slug)[0];

        if (grant) {
            return grant;
        }

        return dispatch('fetchBySlug', { slug });
    },
    getByUri({ dispatch, getters: myGetters, state: myState }, { uri }) {
        const grant = myGetters.getByUri(uri)[0];

        if (grant) {
            return grant;
        }

        return dispatch('fetchByUri', { uri });
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getGrants),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('setGrants', entries);
        }

        return entries;
    },
    async fetchById({ commit, rootGetters }, { id }) {
        const result = await query.call(this, getGrant, { id }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setGrant', entry);
        }

        return entry;
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getGrant, { slug }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setGrant', entry);
        }

        return entry;
    },
    async fetchByUri({ commit, rootGetters }, { uri }) {
        const result = await query.call(this, getGrant, { uri }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setGrant', entry);
        }

        return entry;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setGrants(myState, grants) {
        myState.all = grants;
    },
    setGrant(myState, grant) {
        if (findIndex(myState.all, { id: grant.id }) !== -1) {
            return;
        }

        myState.all = [
            ...myState.all,
            grant
        ];
    },
    clearCache(myState) {
        myState.all = [];
    }
};
