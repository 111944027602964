<template>
    <sf-modal
        :is-visible="isOpen"
        data-modal-name="content"
        @close="$store.dispatch('modal/close')"
    >
        <template
            v-if="modalData && modalData.title"
            v-slot:title
        >
            {{ modalData.title }}
        </template>

        <template
            v-if="modalData && modalData.subTitle"
            v-slot:subTitle
        >
            {{ modalData.subTitle }}
        </template>

        <template
            v-if="modalData && modalData.content"
            v-slot:default
        >
            <article v-html="modalData.content" /> <!-- eslint-disable-line -->
        </template>

        <template
            v-if="modalData && modalData.footer"
            v-slot:footer
        >
            <div v-html="modalData.footer" /> <!-- eslint-disable-line -->
        </template>
    </sf-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import SfModal from '~/patterns/organisms/modal/modal';

export default {
    components: {
        SfModal
    },

    computed: {
        ...mapGetters({
            activeModal: 'modal/activeModal',
            modalData: 'modal/data'
        }),

        isOpen() {
            return this.activeModal === 'content';
        }
    }
};
</script>
