export default function(url) {
    let path = url;

    if (!url) {
        return '/';
    }

    try {
        const urlObject = new URL(url);

        path = urlObject.pathname;
    } catch (err) {
        console.warn(`Invalid URL provided: ${url}`); // eslint-disable-line

        return '/';
    }

    return path;
}
