<template>
    <nav class="sf-menu-list">
        <template v-if="!!$slots.title">
            <slot name="title" />
        </template>
        <ul v-if="!!$slots.custom">
            <slot name="custom" />
        </ul>
        <ul v-if="!$slots.custom && routes && routes.length">
            <li
                v-for="(route, index) in routes"
                :key="`route_${index}`"
            >
                <nuxt-link
                    :to="getLink(route)"
                    :tabindex="indexTabs ? 0 : -1"
                >
                    {{ route.label }}
                </nuxt-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        routes: {
            type: Array,
            default: null
        },
        indexTabs: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        getLink(route) {
            if (route.path) {
                return route.path;
            } else if (route.to) {
                return route.to;
            } else {
                return '';
            }
        }
    }
};
</script>

<style src="./menu-list.less" lang="less"></style>
