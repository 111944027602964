import stringifyGqlQuery from './helpers/stringifiedQuery';
import getSiteIdOnLocale from './helpers/siteId';

/**
 * Helper function to query a GQL backend, passing in variables.
 *
 * Any defaults like headers and variables should be added here.
 *
 * @param any query
 *  A query string or a parsed graphql-tag AST
 * @param {*} variables
 */
export default async function executeGqlQuery(query, variables = {}) {
    const headers = {
        Authorization: this.$config.apiToken
    };

    variables.siteId = await getSiteIdOnLocale(this.$axios, this.$i18n.locale);

    let route;
    if (this.$nuxt && this.$nuxt.context.route) {
        route = this.$nuxt.context.route;
    }

    if (this.route) {
        route = this.route;
    }

    // If the query is executed in the context of a Store module, this is where to obtain the current route.
    if (this.$router && this.$router.currentRoute) {
        route = this.$router.currentRoute;
    }

    // If a Craft preview token is present on the request, send it along as a header to any GQL query (also see examples/craft-cms/plugins)
    if (route && route.query) {
        if (route.query['x-craft-live-preview'] && route.query.token) {
            headers['X-Craft-Token'] = route.query.token;
        }
    }

    // eslint-disable-next-line one-var
    const results = await this.$axios.post('/graphql', {
        query: stringifyGqlQuery(query),
        variables
    }, { headers });

    return results.data;
}
