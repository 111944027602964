<template>
    <transition name="fade">
        <nav
            v-if="isVisible"
            ref="menu"
            class="sf-menu-overlay"
            tabindex="0"
            role="dialog"
            @keydown.esc="$store.dispatch('ui/closeMenu')"
        >
            <div class="sf-menu-overlay__body">
                <sf-escape-hatch />
                <sf-main-menu-list />
                <sf-menu-actions />
                <sf-tagline />
            </div>
        </nav>
    </transition>
</template>

<script>
import SfMainMenuList from '~/patterns/molecules/menu-list/presets/main-menu.vue';
import SfMenuActions from '~/patterns/molecules/menu-actions/menu-actions';
import SfEscapeHatch from '~/patterns/atoms/escape-hatch/escape-hatch';
import SfTagline from '~/patterns/atoms/tagline/tagline';

export default {
    components: {
        SfMainMenuList,
        SfMenuActions,
        SfEscapeHatch,
        SfTagline
    },

    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        isVisible() {
            if (this.isVisible) {
                this.$nextTick(() => {
                    if (this.$refs.menu) {
                        this.$refs.menu.focus();
                    }
                });
            }
        }
    }
};
</script>

<style lang="less" src="./menu-overlay.less" />
