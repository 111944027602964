export default {
    toggleLayoutCollapse({ commit, getters }) {
        commit('isLayoutCollapsed', !getters.isLayoutCollapsed);
    },

    toggleMenu({ commit, getters }) {
        commit('isMenuOpen', !getters.isMenuOpen);
    },

    closeMenu({ commit }) {
        commit('isMenuOpen', false);
    },

    openMenu({ commit }) {
        commit('isMenuOpen', true);
    }
};
