import Vue from 'vue';
import urlToPath from '~/helpers/urlToPath';

export default async function(context, inject) {
    // Add helper functions for global use
    inject('urlToPath', urlToPath); // Makes $urlToPath globally available

    Vue.filter('truncate', function(value, size) {
        if (!value) {
            return '';
        }

        value = value.toString();

        if (value.length <= size) {
            return value;
        }

        return value.substr(0, size) + '...';
    });

    // Fetch everything on page load (server-side)
    if (process.server) {
        await context.store.dispatch('globals/fetchGeneralData');
    }

    // Fetch topics client-side if not filled already
    if (process.client && !context.store.state.globals.general) {
        await context.store.dispatch('globals/fetchGeneralData');
    }
}
