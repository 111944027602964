export default {
    isLayoutCollapsed(state, isCollapsed) {
        state.layout.isCollapsed = isCollapsed;
    },

    isMenuOpen(state, isMenuOpen) {
        state.menu.isOpen = isMenuOpen;

        document.body.setAttribute('data-menu-open', isMenuOpen);
    },

    isNavigationVisible(state, isNavigationVisible) {
        state.navigation.isVisible = isNavigationVisible;
    },

    scrollPosition(state, scrollPosition) {
        state.scrollPosition = scrollPosition;
    },

    isMotionReduced(state, value) {
        state.isMotionReduced = value;
    }
};
