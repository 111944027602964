export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"dateTimeFormats":{"nl":{"day":{"day":"2-digit"},"monthShort":{"month":"short"},"month":{"month":"long","year":"numeric"},"year":{"year":"numeric"},"date":{"year":"numeric","month":"short","day":"numeric"},"dateShort":{"month":"short","day":"numeric"},"dateTime":{"year":"numeric","month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false}},"en":{"day":{"day":"2-digit"},"monthShort":{"month":"short"},"month":{"month":"long","year":"numeric"},"year":{"year":"numeric"},"date":{"year":"numeric","month":"short","day":"numeric"},"dateShort":{"month":"short","day":"numeric"},"dateTime":{"year":"numeric","month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false}}}},
  vueI18nLoader: true,
  locales: [{"code":"nl","iso":"nl-NL","craftSiteHandle":"default","craftSiteId":"1","file":"nl.js"},{"code":"en","iso":"en-US","craftSiteHandle":"english","craftSiteId":"2","file":"en.js"}],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "./locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.stimuleringsfonds.nl",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"nl","iso":"nl-NL","craftSiteHandle":"default","craftSiteId":"1","file":"nl.js"},{"code":"en","iso":"en-US","craftSiteHandle":"english","craftSiteId":"2","file":"en.js"}],
  localeCodes: ["nl","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "n",
  3: "l",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "o",
  15: "c",
  16: "a",
  17: "l",
  18: "e",
  19: "s",
  20: "/",
  21: "n",
  22: "l",
  23: ".",
  24: "j",
  25: "s",
  26: "\"",
  27: ",",
  28: "\"",
  29: "e",
  30: "n",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: "l",
  41: "o",
  42: "c",
  43: "a",
  44: "l",
  45: "e",
  46: "s",
  47: "/",
  48: "e",
  49: "n",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: "}",
}

export const localeMessages = {
  'nl.js': () => import('../../locales/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'en.js': () => import('../../locales/en.js' /* webpackChunkName: "lang-en.js" */),
}
