'use strict';

import query from '~/graphql';
import discoverIndexQuery from '~/graphql/queries/discover/discoverIndex.graphql';
import discoverEntriesQuery from '~/graphql/queries/discover/discoverEntries.graphql';
import homeQuery from '~/graphql/queries/pages/home.graphql';

export const state = () => ({
});

export const actions = {
    async discoverIndex() {
        return await query.call(this, discoverIndexQuery);
    },

    async discoverEntries(context, payload) {
        const variables = {
            limit: payload.limit || 6
        };

        if (payload.tagIds && payload.tagIds.length > 0) {
            variables.tagIds = payload.tagIds;
        }

        if (payload.showOnHome) {
            variables.showOnHome = true;
        }

        if (payload.showInStream) {
            variables.showInStream = true;
        }

        return await query.call(this, discoverEntriesQuery, variables);
    },

    async home() {
        return await query.call(this, homeQuery);
    },
};
