<template>
    <sf-basic-template>
        <template v-slot:header>
            <sf-hero
                v-if="error.statusCode === '404'"
                :title="$t('error.title.generic')"
            />
            <sf-hero
                v-else
                :title="$t('error.title.404')"
            />
        </template>

        <template v-slot:default>
            <sf-page-container>
                <sf-error :error="error" />
            </sf-page-container>
        </template>
    </sf-basic-template>
</template>

<script>
import SfBasicTemplate from '~/patterns/templates/basic.vue';
import SfPageContainer from '~/patterns/organisms/page-container/page-container';
import SfError from '~/patterns/molecules/error/error.vue';
import SfHero from '~/patterns/organisms/hero/hero.vue';

import { NavigationVisible } from '~/mixins/navigation';

export default {
    components: {
        SfBasicTemplate,
        SfError,
        SfHero,
        SfPageContainer
    },
    mixins: [
        NavigationVisible
    ],
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<i18n>
{
    "nl": {
        "error.title.generic": "Er is iets fout gegaan",
        "error.title.404": "Pagina niet gevonden"
    },
    "en": {
        "error.title.generic": "Something went wrong",
        "error.title.404": "Page not found"
    }
}
</i18n>
