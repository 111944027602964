<template>
    <sf-modal
        :is-visible="isOpen"
        data-modal-name="video"
        @close="$store.dispatch('modal/close')"
    >
        <template
            v-if="modalData && modalData.title"
            v-slot:title
        >
            {{ modalData.title }}
        </template>

        <template
            v-if="modalData && modalData.vimeoUrl"
            v-slot:default
        >
            <sf-vimeo-embed
                :title="modalData.title"
                :url="modalData.vimeoUrl"
                :accessible-url="modalData.accessibleVimeoUrl"
            />
        </template>
    </sf-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import SfModal from '~/patterns/organisms/modal/modal';
import SfVimeoEmbed from '~/patterns/molecules/vimeo-embed/vimeo-embed';

export default {
    components: {
        SfModal,
        SfVimeoEmbed
    },

    computed: {
        ...mapGetters({
            activeModal: 'modal/activeModal',
            modalData: 'modal/data'
        }),

        isOpen() {
            return this.activeModal === 'video';
        }
    }
};
</script>
