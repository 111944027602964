<template>
    <div
        class="layout"
        :data-collapsed="isCollapsed ? 'true' : 'false'"
        :data-animation-state="animationState"
    >
        <sf-skip-links />

        <div class="layout__logo" dark-theme-allowed>
            <transition name="cross-fade">
                <template v-if="$i18n.locale === 'nl'">
                    <sf-escape-hatch-discover-nl v-if="animationState === 'discover'" key="1" />
                    <!-- <sf-escape-hatch-foldout-nl v-else-if="animationState === 'foldout'" key="2" /> -->
                    <sf-escape-hatch-block-nl v-else-if="animationState === 'static' && animationIndex === 1" key="3" />
                    <sf-escape-hatch-curvy-nl v-if="animationState === 'static' && animationIndex === 2" key="4" />
                    <sf-escape-hatch-pixel-nl v-if="animationState === 'static' && animationIndex === 3" key="5" />
                    <sf-escape-hatch-shiver-nl v-if="animationState === 'static' && animationIndex === 4" key="6" />
                    <sf-escape-hatch-spike-nl v-if="animationState === 'static' && animationIndex === 5" key="7" />
                </template>
                <template v-if="$i18n.locale === 'en'">
                    <sf-escape-hatch-discover-en v-if="animationState === 'discover'" key="8" />
                    <!-- <sf-escape-hatch-foldout-en v-else-if="animationState === 'foldout'" key="9" /> -->
                    <sf-escape-hatch-block-en v-else-if="animationState === 'static' && animationIndex === 1" key="10" />
                    <sf-escape-hatch-curvy-en v-if="animationState === 'static' && animationIndex === 2" key="11" />
                    <sf-escape-hatch-pixel-en v-if="animationState === 'static' && animationIndex === 3" key="12" />
                    <sf-escape-hatch-shiver-en v-if="animationState === 'static' && animationIndex === 4" key="13" />
                    <sf-escape-hatch-spike-en v-if="animationState === 'static' && animationIndex === 5" key="14" />
                </template>
            </transition>
            <sf-escape-hatch />
        </div>

        <nuxt-link
            :to="localePath('/')"
            class="layout__logo-link"
            :aria-hidden="true"
            tabindex="-1"
        />

        <div class="layout__tagline" dark-theme-allowed>
            <sf-tagline />
        </div>

        <div class="layout__menu-toggle" dark-theme-allowed>
            <sf-button
                class="sf-button--menu-toggle"
                :title="isMenuOpen ? $t('menu-toggle.open-title') : $t('menu-toggle.closed-title')"
                @click="$store.dispatch('ui/toggleMenu')"
            >
                <sf-hamburger
                    :data-toggled="isMenuOpen"
                />
            </sf-button>
        </div>

        <transition name="fade">
            <div
                class="layout__navigation"
                :class="{
                    'layout__navigation--hidden': !navigationVisible
                }"
            >
                <sf-main-menu-list class="sf-menu-list--inline" />
            </div>
        </transition>

        <sf-menu-actions class="layout__actions" dark-theme-allowed />

        <main
            id="main"
            class="layout__body"
            tabindex="-1"
        >
            <nuxt />
        </main>

        <!-- Mobile menu -->
        <sf-menu-overlay :is-visible="isMenuOpen" />

        <sf-footer class="layout__footer" />

        <sf-video-modal />
        <sf-content-modal />

        <client-only>
            <sf-cookie-bar />
        </client-only>

        <transition name="fade">
            <div
                v-if="isCollapsed"
                class="layout__back-to-top"
                dark-theme-allowed
            >
                <sf-button
                    class="dn-button--clean"
                    @click="scrollToTop"
                >
                    <sf-icon icon="arrow-up" />
                    <span class="sr-only">{{ $t('back-to-top') }}</span>
                </sf-button>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { throttle } from 'lodash';

import SfMenuOverlay from '~/patterns/organisms/menu-overlay/menu-overlay.vue';
import SfFooter from '~/patterns/organisms/footer/footer.vue';
import SfVideoModal from '~/patterns/organisms/modal/presets/video';
import SfContentModal from '~/patterns/organisms/modal/presets/content';
import SfMainMenuList from '~/patterns/molecules/menu-list/presets/main-menu.vue';
import SfMenuActions from '~/patterns/molecules/menu-actions/menu-actions';

import SfEscapeHatch from '~/patterns/atoms/escape-hatch/escape-hatch';

import SfEscapeHatchDiscoverNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-discover';
import SfEscapeHatchBlockNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-block';
import SfEscapeHatchCurvyNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-curvy';
import SfEscapeHatchPixelNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-pixel';
import SfEscapeHatchShiverNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-shiver';
import SfEscapeHatchSpikeNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-spike';

import SfEscapeHatchDiscoverEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-discover';
import SfEscapeHatchBlockEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-block';
import SfEscapeHatchCurvyEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-curvy';
import SfEscapeHatchPixelEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-pixel';
import SfEscapeHatchShiverEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-shiver';
import SfEscapeHatchSpikeEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-spike';

import SfHamburger from '~/patterns/atoms/hamburger/hamburger';
import SfButton from '~/patterns/atoms/button/button';
import SfIcon from '~/patterns/atoms/icon/icon';
import SfTagline from '~/patterns/atoms/tagline/tagline';
import SfCookieBar from '~/patterns/organisms/cookie-bar/cookie-bar';
import SfSkipLinks from '~/patterns/atoms/skip-links/skip-links';

import { getLogoAnimationStateByRoute, getLogoStaticAnimationIndex } from '~/helpers/animations';

export default {
    components: {
        SfMenuOverlay,
        SfFooter,
        SfVideoModal,
        SfContentModal,
        SfMainMenuList,
        SfMenuActions,
        SfHamburger,
        SfButton,
        SfIcon,
        SfTagline,
        SfCookieBar,
        SfSkipLinks,

        SfEscapeHatch,

        SfEscapeHatchDiscoverNl,
        SfEscapeHatchBlockNl,
        SfEscapeHatchCurvyNl,
        SfEscapeHatchPixelNl,
        SfEscapeHatchShiverNl,
        SfEscapeHatchSpikeNl,

        SfEscapeHatchDiscoverEn,
        SfEscapeHatchBlockEn,
        SfEscapeHatchCurvyEn,
        SfEscapeHatchPixelEn,
        SfEscapeHatchShiverEn,
        SfEscapeHatchSpikeEn
    },

    data() {
        return {
            animationState: 'static',
            animationIndex: 1
        };
    },

    computed: {
        ...mapGetters({
            isMenuOpen: 'ui/isMenuOpen',
            isCollapsed: 'ui/isLayoutCollapsed',
            navigationVisible: 'ui/isNavigationVisible',
            isMotionReduced: 'ui/isMotionReduced'
        })
    },

    watch: {
        $route() {
            this.animationState = getLogoAnimationStateByRoute(this.$route, this.isMotionReduced);
            this.animationIndex = getLogoStaticAnimationIndex(this.animationIndex);
        }
    },

    mounted() {
        this.updateScrollPosition();
        this.initScrollWatcher();

        this.setMotionReduced();
        this.animationState = getLogoAnimationStateByRoute(this.$route, this.isMotionReduced);
        this.animationIndex = getLogoStaticAnimationIndex(this.animationIndex);
    },

    destroyed() {
        this.destroyScrollWatcher();
    },

    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        initScrollWatcher() {
            this.throttledUpdate = throttle(this.updateScrollPosition, 50);
            window.addEventListener('scroll', this.throttledUpdate, false);
        },

        destroyScrollWatcher() {
            window.removeEventListener('scroll', this.throttledUpdate, false);
        },

        updateScrollPosition(event) {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            this.$store.commit('ui/scrollPosition', scrollTop);
        },

        setMotionReduced() {
            if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
                this.$store.commit('ui/isMotionReduced', true);
            }
        }
    }
};
</script>

<style lang="less" src="./layout.less"></style>

<i18n>
{
    "nl": {
        "menu-toggle.closed-title": "Open menu",
        "menu-toggle.open-title": "Sluit menu",
        "back-to-top": "Terug naar boven"
    },
    "en": {
        "menu-toggle.closed-title": "Open menu",
        "menu-toggle.open-title": "Close menu",
        "back-to-top": "Back to top"
    }
}
</i18n>
